import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter as Router, Route} from 'react-router-dom';

ReactDOM.render(
  <div className="awsui">
      <Router>
          <Route path="/" component={App}/>
      </Router>
  </div>,
  document.getElementById('root')
);
