import {Button, Popover, StatusIndicator} from "@amzn/awsui-components-react";
import React from "react";

/**
 * Single item showing an image inside the Visualiser Dashboard
 */
export default class VisualiserDashboardItem extends React.Component {

    /**
     * Copies image ID to clipboard and starts animation
     */
    async copyToClipboard() {
        await navigator.clipboard.writeText(this.props.item.imageId.toString());
    }

    /**
     * Get info about the image.
     */
    getImageInfo() {
        return <div>
            <p className="imageIdP">
                Image ID: {this.props.item.imageId}
                &nbsp;&nbsp;
                <Popover
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="custom"
                    content={<StatusIndicator type="success">Image ID copied</StatusIndicator>}
                >
                    <Button onClick={() => this.copyToClipboard()} iconName="copy" variant="icon"/>
                </Popover>
            </p>
            <p className="numberOfRequestsP">
                Requests: {this.props.item.numberOfRequests}
            </p>
        </div>;
    }

    render() {
        const item = this.props.item;
        return (
            <div key={item.imageId}>
                <div className="visualiserImageItemContainer">
                    <img onClick={this.props.isSlideshow ? null : this.props.onImageClicked} src={item.imageUrl} alt="Invalid URL" className="visualiserImageItemImage"/>
                </div>
                {this.props.isSlideshow ? null : this.getImageInfo(item)}
            </div>
        );
    }
}