import React from "react";
import VisualiserDateRangeFilter from "./filters/VisualiserDateRangePicker";
import VisualiserSortTypeSelect from "./filters/VisualiserSortTypeSelect";
import VisualiserLocaleSelect from "./filters/VisualiserLocaleSelect";
import VisualiserEntityIdFilter from "./filters/VisualiserEntityIdFilter";
import {Button, Popover} from "@amzn/awsui-components-react/polaris";

/**
 * Component used as a bar that handles all the filters for Visualiser Dashboard
 */
export default class VisualiserFiltersBar extends React.Component {

    /**
     * Constructor for VisualiserFiltersBar
     * @param props takes props onChangeFilters
     */
    constructor(props) {
        super(props);
        this.state = {
            filters: this.props.initialFilters
        };
    }

    /**
     * Get info button to tell more about a filter.
     * @param text the text to be displayed once the button is clicked
     */
    getInfoButton(text) {
        return <Popover
            dismissAriaLabel="Close"
            size="medium"
            triggerType="custom"
            content={text}
        >
            <Button iconName="status-info" variant="inline-icon"/>
        </Popover>;
    }

    /**
     * Handle change of dates in DateRangePicker.
     * @param newFilters the new filters selected
     */
    handleFiltersChange(newFilters) {
        const filters = this.state.filters;
        if ('startDate' in newFilters && 'endDate' in newFilters) {
            filters.startDate = newFilters.startDate;
            filters.endDate = newFilters.endDate;
        }
        if ('locale' in newFilters) {
            filters.locale = newFilters.locale;
        }
        if ('entityId' in newFilters) {
            filters.entityId = newFilters.entityId;
        }
        if ('entityIdMatchType' in newFilters) {
            filters.entityIdMatchType = newFilters.entityIdMatchType;
        }
        if ('imageEntityId' in newFilters) {
            filters.imageEntityId = newFilters.imageEntityId;
        }
        if ('imageEntityIdMatchType' in newFilters) {
            filters.imageEntityIdMatchType = newFilters.imageEntityIdMatchType;
        }
        if ('sortType' in newFilters) {
            filters.sortType = newFilters.sortType;
        }
        this.setState({filters: filters});
    }

    render() {
        return (
            <div className="visualiserDashboardFiltersWrapper">
                <div className="visualiserDashboardFilters">
                    <div className={"spacedFilterWide"}>
                        <p className={"filterTitle"}>Date Range</p>
                        <VisualiserDateRangeFilter
                            onChangeDate={(startDate, endDate) => this.handleFiltersChange({startDate: startDate, endDate: endDate})}
                            initialStartDate={this.state.filters.startDate}
                            initialEndDate={this.state.filters.endDate}
                        />
                    </div>
                    <div className={"spacedFilterNarrow"}>
                        <p className={"filterTitle"}>Locale</p>
                        <VisualiserLocaleSelect
                            onChangeOption={(newLocale) => this.handleFiltersChange({locale: newLocale})}
                            initialOption={this.state.filters.locale}
                        />
                    </div>
                    <div className={"spacedFilterWide"}>
                        <p className={"filterTitle"}>Request entity IDs&nbsp;
                            {this.getInfoButton("Filter by entities an image wa requested for or their expanded counterparts. " +
                                "You can filter by multiple entities by separating them with a comma.")}
                        </p>
                        <VisualiserEntityIdFilter
                            placeholder="Filter by request entity ID"
                            onChangeEntityId={(newEntityId) => this.handleFiltersChange({entityId: newEntityId})}
                            onChangeOption={(newMatchType) => this.handleFiltersChange({entityIdMatchType: newMatchType})}
                            text={this.state.filters.entityId}
                            initialOption={this.state.filters.entityIdMatchType}
                        />
                    </div>
                    <div className={"spacedFilterWide"}>
                        <p className={"filterTitle"}>Image entity IDs&nbsp;
                            {this.getInfoButton("Filter by entities linked with an image. " +
                                "You can filter by multiple entities by separating them with a comma.")}
                        </p>
                        <VisualiserEntityIdFilter
                            placeholder="Filter by image entity ID"
                            onChangeEntityId={(newEntityId) => this.handleFiltersChange({imageEntityId: newEntityId})}
                            onChangeOption={(newMatchType) => this.handleFiltersChange({imageEntityIdMatchType: newMatchType})}
                            text={this.state.filters.imageEntityId}
                            initialOption={this.state.filters.imageEntityIdMatchType}
                        />
                    </div>
                    <div className={"spacedFilterNarrow"}>
                        <p className={"filterTitle"}>Sort by</p>
                        <VisualiserSortTypeSelect
                            onChangeOption={(newSort) => this.handleFiltersChange({sortType: newSort})}
                            initialOption={this.state.filters.sortType}
                        />
                    </div>
                    <div>
                        <p className={"filterTitle"}>&nbsp;</p>
                        <Button variant="primary" onClick={() => this.props.onChangeFilters(this.state.filters)}>Apply</Button>
                    </div>
                </div>
            </div>
        );
    }
}