import Amplify from "aws-amplify";
import { cognitoConfig } from "../config/cognitoConfig"
const callbackUrl =  window.location.origin + '/';

export function initializeAmplify(stage="DEV", region="us-west-2") {
  const oauth = {
    domain: cognitoConfig[stage][region]["domain"],
    scope: ['openid'],
    redirectSignIn: callbackUrl,
    redirectSignOut: callbackUrl,
    responseType: 'code'
  };

  Amplify.configure({
    Auth: {
      userPoolId: cognitoConfig[stage][region]["userPoolId"],
      userPoolWebClientId: cognitoConfig[stage][region]["userPoolWebClientId"],
      mandatorySignIn: true,
      region: region,
      oauth: oauth
    }
  });
}