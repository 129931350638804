import React from "react";
import {Cards, ExpandableSection} from "@amzn/awsui-components-react";
import VisualiserDashboard from "../model/VisualiserDashboard";
import VisualiserFiltersBar from "../components/VisualiserFiltersBar";
import RedirectPage from "../components/RedirectPage";
import NoAccessPage from "../components/NoAccessPage";
import InfiniteScroll from 'react-infinite-scroller';
import {apiState} from "./apiState";
import VisualiserConfigBar from "../components/VisualiserConfigBar";

// Maximum number of results about to be returned
const MAX_RESULTS = '101';

/**
 * Component handling calling API to get Visualiser dashboard.
 * Shows loading, error or VisualiserDashboard based on currect API result
 */
export default class GetVisualiserDashboard extends React.Component {

    /**
     * Constructor for GetVisualiserDashboard
     * @param props takes props visualiserClient and infoExpanded
     */
    constructor(props) {
        super(props);
        this.infoExpanded = props.infoExpanded;
        this.nextToken = null;
        this.queryExecutionId = null;
        this.state = {
            apiState: apiState.LOADING,
            data: null,
            hasMore: true,
            showMoreLoader: true,
            filters: props.filters,
            selectedMode: 'dashboard',
            numberOfColumns: localStorage.getItem('numberOfColumns') ?? 4
        };
    }

    /**
     * Initiate API call when component is created
     */
    async componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
        // scroll to top on refresh (prevent jumping to bottom as we have pagination working)
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
        await this.getImagesFromApi(true);
    }

    /**
     * Remove listeners when component is unmounted
     */
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    /**
     * Call API to get data for Visualiser dashboard. Update state according to API response.
     * @param startNew indicate whether new request is starting or we are continuing in pagination of the old one
     */
    getImagesFromApi = async (startNew) => {
        if (startNew) {
            this.props.onChangeFilters(this.state.filters);
            this.queryExecutionId = null;
            this.nextToken = null;
            this.setState({apiState: apiState.LOADING, hasMore: true, showMoreLoader: true, data: null});
        }
        const filters = {...this.state.filters};
        filters.queryExecutionId = this.queryExecutionId;
        filters.nextToken = this.nextToken;
        filters.maxResults = MAX_RESULTS;
        const response = await this.props.visualiserClient.getImages(filters);
        if (!startNew && (response === null || !response.results || response.results.length === 0)) {
            this.setState({showMoreLoader: false, hasMore: false});
            return;
        }
        if (response === null) {
            this.setState({apiState: apiState.LOADING, hasMore: false});
            return;
        }

        if (!response.results) {
            this.setState({apiState: apiState.ERROR, data: response, hasMore: false});
            return;
        }

        if (response.results.length === 0) {
            this.setState({apiState: apiState.NO_DATA, hasMore: false});
        } else {
            const currentData = this.state.data ?? [];
            const hasMore = response.results.length === (MAX_RESULTS - 1);
            this.nextToken = response.nextToken;
            this.queryExecutionId = response.queryExecutionId;
            this.setState({apiState: apiState.SUCCESS, data: currentData.concat(response.results), hasMore: hasMore});
        }
    }

    /**
     * Callback function for changing filters
     * @param newFilters the filters to be updated
     */
    async onChangeFilters(newFilters) {
        this.setState({filters: newFilters});
        await this.getImagesFromApi(true);
    }

    /**
     * Remember last state of info in local storage
     */
    onChangeInfoExpanded() {
        this.infoExpanded = !this.infoExpanded;
        localStorage.setItem('showInstructions', this.infoExpanded ? "true" : "false");
    }

    /**
     * Get instructions section
     */
    getInstructions() {
        return <ExpandableSection
            header="Instructions"
            onChange={(_) => this.onChangeInfoExpanded()}
            defaultExpanded={this.infoExpanded}
        >
            <p>
                Welcome to AMD Traffic Visualizer. This tool enables you to browse global traffic (DiscoverImages calls) to
                Alexa Multimedia Discovery (AMD), the interface to Alexa's corpus of millions of licensed and creative commons
                images from sources such as Getty Images, IMDb, Wikipedia and HBO.
            </p>
            <p>
                By default, this tool shows you images for last hour, for all regions and entity categories, sorted by popularity.
            </p>
            <p style={{marginBottom: 0}}>
                You may:
            </p>
            <ul style={{marginTop: 0}}>
                <li>Filter by: relative and absolute date ranges, locale, request entity ID, image entity ID</li>
                <li>Sort by: Most popular or least popular</li>
            </ul>
        </ExpandableSection>
    }

    /**
     * Show dashboard content based on api state
     * Loading if we don't have result yet, data in VisualiserDashboard if we have them, error if we don't, no data if data empty
     */
    getDashboard() {
        switch (this.state.apiState) {
            case apiState.LOADING:
                return <Cards loading={true} cardDefinition={{sections: []}} items={[]}/>;
            case apiState.ERROR:
                return <p>{this.state.result}</p>;
            case apiState.NO_DATA:
                return <p>No Images found.</p>;
            default:
                return <InfiniteScroll
                    pageStart={0}
                    loadMore={() => this.getImagesFromApi(false)}
                    hasMore={this.state.apiState === apiState.SUCCESS && this.state.hasMore}
                    loader={<Cards key={"loading"} loading={this.state.showMoreLoader} cardDefinition={{sections: []}} items={[]}/>}
                >
                    <VisualiserDashboard
                        results={this.state.data}
                        stage={this.props.stage}
                        isSlideshow={this.isSlideshow()}
                        numberOfColumns={this.state.numberOfColumns}
                    />
                </InfiniteScroll>;
        }
    }

    /**
     * Get whether we reached the bottom of an element
     * @param element the element to be checked
     * @return {boolean} whether we reached the bottom of an element
     */
    isBottom(element) {
        return element.getBoundingClientRect().bottom <= window.innerHeight;
    }

    /**
     * Track the scrolling of the automatic scroller and jump to the top once bottom is reached
     */
    trackScrolling = () => {
        const wrappedElement = document.getElementById('visualiserGrid');
        if (this.isSlideshow() && !this.state.hasMore && this.isBottom(wrappedElement)) {
            window.scrollTo(0, 0);
        }
    }

    /**
     * Check whether slideshow mode is active or not.
     */
    isSlideshow() {
        return this.state.selectedMode === 'slideshow';
    }

    /**
     * Start scrolling the page automatically.
     * @param firstTime whether the scroll should be allowed the first time
     */
    scrollPageAutomatically(firstTime = false) {
        if (this.state.selectedMode === 'slideshow' || firstTime) {
            window.scrollBy(0, 1);
            setTimeout(() => this.scrollPageAutomatically(), 10);
        }
    }

    render() {
        if (this.props.stage === "GAMMA" || (this.props.stage === "PROD" && this.props.region !== "us-east-1")) {
            return <RedirectPage stage={this.props.stage}/>;
        }
        // only allow users from this LDAP group access to Visualiser
        if (this.props.ldapGroup !== "amd-visualizer-users") {
            return <NoAccessPage/>;
        }
        return (
            <div style={{position: 'relative'}}>
                {this.isSlideshow() ? null : this.getInstructions()}
                <div className="stickyHeader">
                    <VisualiserConfigBar
                        selectedMode={this.state.selectedMode}
                        defaultNumberOfColumns={this.state.numberOfColumns}
                        tinyUrlServiceClient={this.props.tinyUrlServiceClient}
                        onChangeColumns={(columns) => {
                            this.setState({numberOfColumns: columns});
                            localStorage.setItem('numberOfColumns', columns);
                        }}
                        onChange={({ detail }) => {
                            if (this.state.apiState === apiState.SUCCESS) {
                                this.setState({selectedMode: detail.selectedId});
                                if (detail.selectedId === "slideshow") {
                                    this.scrollPageAutomatically(true);
                                }
                            }
                        }}
                    />
                    {this.isSlideshow() ? null : <VisualiserFiltersBar
                        initialFilters={this.state.filters}
                        onChangeFilters={(newFilters) => this.onChangeFilters(newFilters)}
                    />}
                </div>
                <div className="visualiserDashboardWrapper">
                    {this.getDashboard()}
                </div>
            </div>
        );
    }
}