import ApiCallerClient, {ApiMethod} from "./apiCallerClient";

/**
 * Class that handles API calls for Tracer's part of the website
 */
export default class TracerServiceClient {

    /**
     * Constructor of TracerServiceClient
     * @param apiHost used as first part of URL for API request
     */
    constructor(apiHost) {
        this.apiHost = apiHost;
    }

    /**
     * Get images API. Calls AMD Get Images.
     * @param requestParameters parameters to be inserted into the request URL
     * @returns {Promise<string|*|Response>} response with data or error
     */
    getImages = async (requestParameters) => {
        const path = `/prod/v1/images/${requestParameters.imageId}/assets/${requestParameters.maxWidth}/${requestParameters.minWidth}/` +
            `${requestParameters.maxHeight}/${requestParameters.minHeight}/${requestParameters.aspectRatio}`;
        return await ApiCallerClient.callApi({
            "endpoint": {
                "hostName": this.apiHost,
                "httpMethod": ApiMethod.GET,
                "path": path
            },
            "body": null
        });
    }

    /**
     * Discover Images API. Calls AMD Discover Images.
     * @param body body of the request
     * @returns {Promise<string|*|Response>} response with data or error
     */
    discoverImages = async (body) => {
        const path = `/prod/v1/images/discover`;
        return await ApiCallerClient.callApi({
            "endpoint": {
                "hostName": this.apiHost,
                "httpMethod": ApiMethod.POST,
                "path": path
            },
            "body": body
        });
    }

    /**
     * Discover Editorial Images API. Calls AMD Discover Editorial Images.
     * @param body body of the request
     * @returns {Promise<string|*|Response>} response with data or error
     */
         discoverEditorialImages = async (body) => {
            const path = `/prod/v1/images/discover/editorial`;
            return await ApiCallerClient.callApi({
                "endpoint": {
                    "hostName": this.apiHost,
                    "httpMethod": ApiMethod.POST,
                    "path": path
                },
                "body": body
            });
        }

    /**
     * Discover Images API with UAC. Calls AMD Discover Images v1.1.
     * @param body body of the request
     * @returns {Promise<string|*|Response>} response with data or error
     */
    discoverImagesWithUAC = async (body) => {
        const path = `/prod/v1.1/images/discover`;
        return await ApiCallerClient.callApi({
            "endpoint": {
                "hostName": this.apiHost,
                "httpMethod": ApiMethod.POST,
                "path": path
            },
            "body": body
        });
    }

    /**
     * Discover Images V2 API. Calls AMD Discover Images.
     * @param body body of the request
     * @returns {Promise<string|*|Response>} response with data or error
     */
    discoverImagesV2 = async (body) => {
        const path = `/prod/v2/images/discover`;
        return await ApiCallerClient.callApi({
            "endpoint": {
                "hostName": this.apiHost,
                "httpMethod": ApiMethod.POST,
                "path": path
            },
            "body": body
        });
    }
}