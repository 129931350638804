import {Select} from "@amzn/awsui-components-react";
import React from "react";
import {getInitialOption} from "../../helpers/visualiserFunctions";

/**
 * Selector for sort type for the Visualiser dashboard
 */
export default class VisualiserSortTypeSelect extends React.Component {

    /**
     * Constructor for VisualiserSortTypeSelect
     * @param props takes props onChangeOption that gets triggered whenever sort type changes
     */
    constructor(props) {
        super(props);
        this.options = [
            { label: "Most popular", value: "MOST_POPULAR" },
            { label: "Least popular", value: "LEAST_POPULAR" }
        ];
        this.state = {
            selectedOption: getInitialOption(props, this.options)
        };
    }

    /**
     * Callback function for changing sort for filters
     * @param selectedOption the type of filter selected
     */
    onChange(selectedOption) {
        this.setState({ selectedOption: selectedOption });
        this.props.onChangeOption(selectedOption.value);
    }

    render() {
        return (
            <Select
                selectedOption={this.state.selectedOption}
                onChange={({ detail }) => this.onChange(detail.selectedOption)}
                options={this.options}
            />
        );
    }
}