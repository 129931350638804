import React from "react";
import {Button} from "@amzn/awsui-components-react";

/**
 * Component used as a page to indicate that the user doesn't have access to Visualiser
 */
export default class NoAccessPage extends React.Component {

    render() {
        const ticketUrl = "https://t.corp.amazon.com/create/templates/5bca8867-7528-4d58-8cfa-f6d9792e22e6";
        return <div className="centeredDiv">
            <p>Oops. You don't have access to AMD Visualizer. Please request access below.</p>
            <a href={ticketUrl} target="_blank" className="buttonLink" rel="noopener noreferrer">
                <Button variant="primary">
                    Request access
                </Button>
            </a>
        </div>
    }
}