export const cognitoConfig = {
  'DEV': {
    'us-west-2': {
      domain: "cog-amdtracer-beta.auth.us-west-2.amazoncognito.com",
      userPoolId: "us-west-2_lBFlV2FNB",
      userPoolWebClientId: "6guns9jg2nt3r67aqviv9s7ls3"
    }
  },
  'BETA': {
    'us-west-2': {
      domain: "cog-amdtracer-beta.auth.us-west-2.amazoncognito.com",
      userPoolId: "us-west-2_lBFlV2FNB",
      userPoolWebClientId: "6guns9jg2nt3r67aqviv9s7ls3"
    }
  },
  'GAMMA': {
    'eu-west-1': {
      domain: "cog-amdtracer-gamma-dub.auth.eu-west-1.amazoncognito.com",
      userPoolId: "eu-west-1_OQehkZS0b",
      userPoolWebClientId: "27p6bah2kuc49pqr3q9sp17ao9"
    },
    'us-east-1': {
      domain: "cog-amdtracer-gamma-iad.auth.us-east-1.amazoncognito.com",
      userPoolId: "us-east-1_xRwacCrcm",
      userPoolWebClientId: "33q8mk3lhe48p52fa2qij5p2ru"
    },
    'us-west-2': {
      domain: "cog-amdtracer-gamma-pdx.auth.us-west-2.amazoncognito.com",
      userPoolId: "us-west-2_lJ5HIxgsv",
      userPoolWebClientId: "1v46ahk88o9sljlnem9li6poh0"
    }
  },
  'PROD': {
    'eu-west-1': {
      domain: "cog-amdtracer-dub.auth.eu-west-1.amazoncognito.com",
      userPoolId: "eu-west-1_sgXxptJM9",
      userPoolWebClientId: "3po44nqp8go8dvojdhl4qslji6"
    },
    'us-east-1': {
      domain: "cog-amdtracer-iad.auth.us-east-1.amazoncognito.com",
      userPoolId: "us-east-1_IFuyqFFmk",
      userPoolWebClientId: "38o3rari0c6akjavbdbcfc4i6q"
    },
    'us-west-2': {
      domain: "cog-amdtracer-pdx.auth.us-west-2.amazoncognito.com",
      userPoolId: "us-west-2_zUWy4621H",
      userPoolWebClientId: "265r4u53v4nmh5m407j62t9jf4"
    }
  }
}