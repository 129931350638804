import {Button, Popover, StatusIndicator} from "@amzn/awsui-components-react";
import React from "react";

/**
 * Button used to shorten and copy current URL.
 */
export default class CopyURLButton extends React.Component {

    /**
     * Constructor for CopyURLButton.
     * @param props tinyUrlServiceClient
     */
    constructor(props) {
        super(props);
        this.state = {
            copyState: null
        }
    }

    /**
     * Try to create shortened URL and save it to clipboard.
     */
    async onShareURLClicked() {
        const result = await this.props.tinyUrlServiceClient.getTinyURL(window.location.href);
        if ('short_url' in result) {
            await navigator.clipboard.writeText(result.short_url);
            this.setState({copyState: 'success'});
        } else {
            await navigator.clipboard.writeText(window.location.href);
            this.setState({copyState: 'error'});
        }
    }

    /**
     * Get content of the popup based on current state.
     */
    getPopupContent() {
        if (this.state.copyState === 'success') {
            return <StatusIndicator type="success">Tiny URL copied</StatusIndicator>;
        } else if (this.state.copyState === 'error') {
            return <StatusIndicator type="info">Failed to shorten URL, copied full URL</StatusIndicator>;
        }
        return null;
    }

    render() {
        return <Popover
            dismissButton={false}
            position="top"
            size="small"
            triggerType="custom"
            content={this.getPopupContent()}
        >
            <Button variant="normal" iconName="copy" onClick={() => this.onShareURLClicked()}>Copy URL</Button>
        </Popover>;
    }
}