import React from 'react';
import {Tabs} from "@amzn/awsui-components-react/polaris";
import GetImages from './apis/GetImages';
import DiscoverImages from './apis/DiscoverImages';
import DiscoverImagesV2 from './apis/DiscoverImagesV2';
import DiscoverEditorialImages from './apis/DiscoverEditorialImages';
import { initializeAmplify } from './auth/intitalizeCognito'
import {apiHost} from './config/apiConfig'
import {Auth, Hub} from "aws-amplify";
import GetVisualiserDashboard from "./apis/GetVisualiserDashboard";
import VisualiserServiceClient from "./clients/visualiserServiceClient";
import TracerServiceClient from "./clients/tracerServiceClient";
import {getFiltersFromQueryParams, parseFiltersToParams} from "./helpers/visualiserFunctions";
import TinyUrlServiceClient from "./clients/tinyUrlServiceClient";

const currentUrl = window.location.origin;

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      region: 'us-west-2',
      stage: 'DEV',
      activeTabId: this.getActiveTabId()
    };

    //get stage.
    //component is not mounted so this.setState doesn't work.
    this.state.stage = this.getStage();
    console.debug("Stage set to: " + this.state.stage);

    //get region.
    //component is not mounted so this.setState doesn't work.
    this.state.region = this.getRegion();
    console.debug("Region set to: " + this.state.region);
  }

  // get tab based on path given or last opened tab if no (or wrong) path given
  getActiveTabId() {
    const path = this.props.location.pathname;
    if (path === '/tracer' || path === '/visualizer') {
      const tabPath = path.substring(1);
      localStorage.setItem('lastTab', tabPath);
      return tabPath;
    }
    return localStorage.getItem('lastTab') ?? 'tracer';
  }

  //get the stage of the environment.
  getStage = () => {
    if (currentUrl.includes("beta")) {
      return "BETA";
    }
    if (currentUrl.includes("gamma")) {
      return "GAMMA";
    }
    if (currentUrl.includes("prod")) {
      return "PROD";
    }
    return "DEV";
  }

  getRegion = () => {
    if (this.state.stage === "DEV" || this.state.stage === "BETA") {
      return "us-west-2";
    }
    if (currentUrl.includes("dub")) {
      return "eu-west-1";
    }
    
    if (currentUrl.includes("iad")) {
      return "us-east-1";
    }

    if (currentUrl.includes("pdx")) {
      return "us-west-2";
    }

    console.debug("Couldn't determine region. Defaulting to PDX");
    return "us-west-2"
  }

  //create a amplify listener.
  createAmplifyListener = () => {
    Hub.listen('auth', ({payload: {event, data}}) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          console.debug("In cognito hosted ui: ", data)
          this.createServiceClients();
          this.setState({user: data});
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.debug('Sign in failure', data);
          break;
        default:
          console.debug("Event didn't match any of the expected events: ", event);
      }
    });
  }

  //authenticate the user.
  doCognitoAuth = async () => {
    //fetch the user credentials if midway has already authenticated the user.
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.createServiceClients();
      this.setState({user: user});
      console.debug('Authenticated user ', user.getUsername());
    } catch(error) {
      console.debug('Not signed in. Error: ',  error);
      //if couldn't fetch credentials, sign in the user by federate:
      await Auth.federatedSignIn({customProvider: "FederateOIDC"});
    }
  }

  // create clients for calling services
  createServiceClients() {
    this.visualiserClient = new VisualiserServiceClient(apiHost[this.state.stage][this.state.region]);
    this.tracerServiceClient = new TracerServiceClient(apiHost[this.state.stage][this.state.region]);
    this.tinyUrlServiceClient = new TinyUrlServiceClient();
  }

  //sign the user into the default region (PDX) for now:
  componentDidMount = async () => {
    initializeAmplify(this.state.stage, this.state.region);
    this.createAmplifyListener();
    await this.doCognitoAuth();
  }

  // create tabs for selecting Tracer's content
  createTracerTabs = () => {
    return <Tabs tabs={[
      {
        label: "Get Images",
        id: "getImages",
        content: <GetImages
            tracerServiceClient={this.tracerServiceClient}
        />
      },
      {
        label: "Discover Images",
        id: "discoverImages",
        content: <DiscoverImages
            tracerServiceClient={this.tracerServiceClient}
        />
      },
      {
        label: "Discover Editorial Images",
        id: "discoverEditorialImages",
        content: <DiscoverEditorialImages
            tracerServiceClient={this.tracerServiceClient}
        />
      },
      {
        label: "Discover Images With UAC",
        id: "discoverImagesWithUAC",
        content: "Feature coming soon"
      },
      {
        label: "Discover Images V2",
        id: "discoverImagesV2",
        content: <DiscoverImagesV2
            tracerServiceClient={this.tracerServiceClient}
        />
      }
    ]} />
  }

  // create Tracer's part of the website
  createTracer = () => {
    return <div>
      <h1> Alexa Multimedia Discovery Tracer </h1>
      <p>
        Stage: {this.state.stage}
        <br />
        Region: {this.state.region}
      </p>
      {this.createTracerTabs()}
    </div>
  }

  // create Visualiser's part of the website
  createVisualiser = () => {
    return <GetVisualiserDashboard
        visualiserClient={this.visualiserClient}
        tinyUrlServiceClient={this.tinyUrlServiceClient}
        infoExpanded={localStorage.getItem('showInstructions') !== "false"}
        stage={this.state.stage}
        region={this.state.region}
        ldapGroup={this.state.user.getSignInUserSession().getIdToken().decodePayload()["custom:LDAP_GROUP"]}
        filters={getFiltersFromQueryParams(this.props.location.search)}
        onChangeFilters={(newFilters) => this.props.history.push({
          pathname: '/visualizer',
          search: "?" + new URLSearchParams(parseFiltersToParams(newFilters)).toString()
        })}
    />
  }

  // Handle different tab selection
  onChangeTab(newTab) {
    localStorage.setItem('lastTab', newTab);
    this.props.history.push({
      pathname: '/' + newTab,
      search: ''
    });
    this.setState({ activeTabId: newTab })
  }

  // create Main tabs for Tracer and Visualiser
  createTabs = () => {
    return <Tabs
        activeTabId={this.state.activeTabId}
        onChange={event => this.onChangeTab(event.detail.activeTabId)}
        tabs={[
          {
            label: "AMD Image Tracer",
            id: "tracer",
            content: this.createTracer()
          },
          {
            label: "AMD Traffic Visualizer",
            id: "visualizer",
            content: this.createVisualiser()
          }
        ]}
    />
  }

  render() {
    //display api tabs only if user credentials were obtained or if running on localhost.
    let tabs;
    if (this.state.user !== null) {
      tabs = this.createTabs();
    } else {
      tabs = <p>Authentication in progress... <i>(this could also be an error)</i></p>
    }
    
    return (
      <div style={{ marginLeft: '5%', marginRight: '5%'}}>
        {tabs}
      </div>
    );
  }
}
