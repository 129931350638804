import React from "react";
import { Form, FormField, Input, Button } from "@amzn/awsui-components-react/polaris";
import Result from "../model/Result";
import { isGetImagesInputValid } from "../validator/InputValidator";

export default class GetImages extends React.Component {
  
  state = {
    input: {
      imageId: "",
      maxHeight: "1000",
      maxWidth: "1000",
      minHeight: "0",
      minWidth: "0",
      aspectRatio: "1.0",
    },
    formValidationError: "",
    result: null,
    responseTitle: "GetImages Response",
    isFormSubmitted: false
  }

  handleChange = (event, id) => {
    event.preventDefault();
    //Updating an attrbute of the input object using the spread operator.
    //get the previous state for input and add the new value.
    this.setState( prevState => ({
      input: {
        ...prevState.input,
        [id]: event.detail.value
      }
    }));
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.resetState();

    //Form validation
    let formValidationError = isGetImagesInputValid(this.state.input);
    if (formValidationError !== undefined){
      //error while validating schema.
      this.setState({formValidationError: formValidationError.message});
      return;
    }

    //show loading table
    this.setState({
      isFormSubmitted: true,
      result: {"images": []}
    })

    await this.getImagesFromApi();
  }

  getImagesFromApi = async () => {
    let requestParameters = this.state.input;
    const response = await this.props.tracerServiceClient.getImages(requestParameters);
    this.setState({ result: response });
  }

  resetState = () => {
    this.setState({
      formValidationError: "",
      isFormSubmitted: false,
      result: null
    })
  }

  render() {

    return (
      <div className='GetImages'>
      <div style={{ marginLeft: '5%', marginRight: '5%'}}>
      <Form header="Call to AMD GetImages API"
        errorText={this.state.formValidationError}
        actions={
          <div>
            <br />
            <Button formAction="submit" onClick={this.handleSubmit} variant="primary"> Call AMD </Button>
          </div>
        }
      >
        <FormField label={<span>Image ID <b style={{color:'red'}}>*</b></span>}>
          <Input id="imageId" type="text" placeholder="00d9b0d407b000ae2475021fbbf1c4b4" value={this.state.input.imageId}
                 onChange={(event) => this.handleChange(event, "imageId")}/>
        </FormField>
        
        <FormField label="maxHeight" >
          <Input id="maxHeight" type="number" value={this.state.input.maxHeight}
                 onChange={(event) => this.handleChange(event, "maxHeight")}/>
        </FormField>

        <FormField label="maxWidth" >
          <Input id="maxWidth" type="number" value={this.state.input.maxWidth}
                 onChange={(event) => this.handleChange(event, "maxWidth")}/>
        </FormField>

        <FormField label="minHeight" >
          <Input id="minHeight" type="number" value={this.state.input.minHeight}
                 onChange={(event) => this.handleChange(event, "minHeight")}/>
        </FormField>

        <FormField label="minWidth" >
          <Input id="minWidth" type="number" value={this.state.input.minWidth}
                 onChange={(event) => this.handleChange(event, "minWidth")}/>
        </FormField>

        <FormField label="Aspect Ratio" >
          <Input id="aspectRatio" type="number" value={this.state.input.aspectRatio}
                 onChange={(event) => this.handleChange(event, "aspectRatio")}/>
        </FormField>
      </Form> 
      </div>
      {this.state.isFormSubmitted === true && this.state.result !== null
      ? <Result data={this.state.result} title={this.state.responseTitle} /> 
      : <div/>}
    </div>
    );    
  }
}