import React from "react";
import {Button, Cards, ColumnLayout, Modal, Tabs} from "@amzn/awsui-components-react/polaris";
import _ from 'lodash';

const FIXED_HEIGHT = 200;

const DEFAULT_VALUE = "-";

export default class ImageCards extends React.Component {

  state = {
    imageIndexToShow: null
  }

  getImageFromURL = (url, width, height) => {
    let calculatedWidth = FIXED_HEIGHT * width / height;
    return <img src={url} alt={url} width={calculatedWidth} height={FIXED_HEIGHT}
      style={{display:'block', marginLeft:'auto', marginRight:'auto'}}/>
  }

  //method that stores what button/image was clicked.
  handleClick = (event, index) => {
    event.preventDefault();
    this.setState({imageIndexToShow: index});
  }

  //display the image as a button.
  renderImageButtons = () => {
    return this.props.images.map((image, index) => {
      return (
          <div key={"btn-" + index}><center>
            <Button variant="link" onClick={(event) => this.handleClick(event, index)} id={index} key={index}>
              {this.getImageFromURL(image["url"], image["width"], image["height"])}
            </Button>
          </center></div>
      )
    });
  }

  //when an image button is clicked, display the appropriate modal.
  //within the modal, display image attributes as a card.
  renderModalForImage = () => {
    let modal;
    const index = this.state.imageIndexToShow;
    if (index !== null) {
      let imageAttributes = _.cloneDeep(this.props.images[index]);
      imageAttributes['caption'] = _.get(imageAttributes, "source_metadata.caption", DEFAULT_VALUE)
      imageAttributes['creation_date'] = _.get(imageAttributes, "source_metadata.creation_date", DEFAULT_VALUE)
      //delete the 'source_metadata' JSON object for editorial image modals
      delete imageAttributes['source_metadata'];
      //Modal will show the image, and attributes as a JSON and card.
      modal = <Modal
          header="Image Information"
          visible={this.state.imageIndexToShow !== null}
          onDismiss={() => this.setState({ imageIndexToShow: null })}
      >
        {this.getImageFromURL(imageAttributes["url"], imageAttributes["width"], imageAttributes["height"])}
        <Tabs tabs={[
          {
            label: "As a Card",
            id: "asCard",
            content: this.createCardForImage(imageAttributes)
          },
          {
            label: "As JSON",  
            id: "asJSON", 
            content: <div><pre style={{whiteSpace: "pre-wrap", wordWrap: "break-word"}}>{JSON.stringify(imageAttributes, null, 2)}</pre></div>
          }
        ]}/>   
      </Modal>
    }
    return modal;
  }

  isBlank = (value) => {
    return _.isEmpty(value) && !_.isNumber(value);
  }

  // when a field is an array of objects react can't render it
  // that is helper function that translates it to string
  convertArrayOfObjectFieldsToJson = (fieldValue) => {
    if (!Array.isArray(fieldValue)) {
      if (fieldValue !== Object(fieldValue)) {
        return this.isBlank(fieldValue) ? DEFAULT_VALUE : fieldValue;
      }
      // creating array of key-value pairs from Object [{ key1: value1 }, { key2: value2 }]
      fieldValue = Object.entries(fieldValue).map((entry) => {
        return {[entry[0]]: entry[1]}
      });
    }

    if (_.isEmpty(fieldValue)) {
      return DEFAULT_VALUE;
    }

    return fieldValue.map((element) => {
      if (element !== Object(element)) {
        return element;
      }
      
      return <div>{JSON.stringify(element, null, 2)}</div>;
    });
  }

  //display all image properties in a card.
  createCardForImage = (imageAttributes) => {
    const imageUrl = imageAttributes["url"];
    let cardDefinition = {
      sections: Object.keys(imageAttributes).map((key) => {
        return {
          id: key,
          header: key,
          width: 50,
          //if key is url, display it as a link which opens in a new tab. Otherwise just render the attribute.
          content: item => (key === "url" ? <a href={imageUrl} target="_blank" rel="noopener noreferrer">Link to the Image</a> : this.convertArrayOfObjectFieldsToJson(item[key]))
        }
      })
    };
    return <Cards cardDefinition={cardDefinition} items={[imageAttributes]}/>
  }

  render() {
    return (
      <div>
        <hr />
        <br />
        <h2 style={{ textAlign: 'center' }}>Click on the image to learn more about it!</h2>
        <br />
        <hr />
        <ColumnLayout columns={3}>
          {/* Order the image buttons in a column */}
            {this.renderImageButtons()}
        </ColumnLayout>
        {this.renderModalForImage()}
      </div>
    )
  }
}