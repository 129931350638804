// Example request body for Discover Images v2 taken from:
// https://code.amazon.com/packages/AlexaMultimediaDiscoveryService/blobs/mainline/--/data/v2/discover/images/request-with-query-and-entities.json
export const exampleDiscoverImagesV2RequestBody = JSON.stringify(
    {
        "query_text": "obama in a late night show",
        "response_dimensions": {
            "size": "5"
        },
        "request_metadata": {
            "locale": "string",
            "device_type_id": "string",
            "marketplace_id": "string",
            "country_of_residence": "string",
            "customer_id": "string",
            "utterance_id": "string"
        }
    }, null, 2);
    