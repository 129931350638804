export const apiHost = {
  "DEV": {
    "us-west-2": 'https://l0v7hqc056.execute-api.us-west-2.amazonaws.com'
  },
  "BETA": {
    "us-west-2": 'https://i9p4cit4gb.execute-api.us-west-2.amazonaws.com'
  },
  "GAMMA": {
    "eu-west-1": 'https://ns5dsxvcz0.execute-api.eu-west-1.amazonaws.com',
    "us-east-1": 'https://bh1vge71si.execute-api.us-east-1.amazonaws.com',
    "us-west-2": 'https://ubok6bqwhd.execute-api.us-west-2.amazonaws.com'
  },
  "PROD": {
    "eu-west-1": 'https://j7ch8r2cui.execute-api.eu-west-1.amazonaws.com',
    "us-east-1": 'https://h1hpn38ap8.execute-api.us-east-1.amazonaws.com',
    "us-west-2": 'https://0y7yf1vt2c.execute-api.us-west-2.amazonaws.com'
  }
}