import React from "react";
import { Cards } from "@amzn/awsui-components-react/polaris";
import ImageCards from "./ImageCards"


export default class Result extends React.Component {

  renderError = () => {
    return <div><pre>{JSON.stringify(this.props.data, null, 2)}</pre></div>;
  }

  render() {

    let cardsOrError;
    /**
     * if no image key in this.props.data -> error. this.props.data has the error message.
     * if image key exists but no data in images -> still awating on response from backend. Display "loading"
     * If image key exists and there is image data -> show the images.
     */
    try {
      if("images" in this.props.data) {
        const images = this.props.data["images"];
        cardsOrError = images.length === 0
            ? <Cards
                loading={true}
                loadingText="Calling AMD..."
                header={<h2>{this.props.title}</h2>}
                cardDefinition={{sections: []}}
                items={[]}/>
            : <ImageCards images={images} title={this.props.title} />
      } else {
        //if no images key -> error
        cardsOrError = this.renderError();
      }
    } catch (exception) {
      //if this.props.data is not an object or any other error occured:
      cardsOrError = this.renderError();
    }
    
    return (
      <div>
        <br />
        {cardsOrError}
      </div>
    );
  }

}