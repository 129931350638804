import ApiCallerClient, {ApiMethod} from "./apiCallerClient";

/**
 * Class that handles API calls for Visualiser's part of the website
 */
export default class VisualiserServiceClient {

    /**
     * Constructor of VisualiserServiceClient
     * @param apiHost used as first part of URL for API request
     */
    constructor(apiHost) {
        this.apiHost = apiHost;
    }

    /**
     * Get Images API. Queries S3 logs to look for images users queried AMD for.
     * @param params Parameters for filters applied. Structure is:
     * {
     *     "startDate": ...,
     *     "endDate": ...
     *     "sortType": ...,
     *     "locale": ...,
     *     "entityId": ...,
     *     "entityIdMatchType": ...,
     *     "imageEntityId": ...,
     *     "imageEntityIdMatchType": ...
     *     "nextToken": ...,
     *     "queryExecutionId": ...,
     *     "maxResults": ...
     * }
     * @returns {Promise<string|*|Response>} response with data or error
     */
    getImages = async (params) => {
        let path = '/prod/v1/image-io-logs/images' + this.encodeQueryParams(this.getQueryParams(params));
        return await ApiCallerClient.callApi({
            "endpoint": {
                "hostName": this.apiHost,
                "httpMethod": ApiMethod.GET,
                "path": path
            },
            "body": null
        });
    }

    /**
     * Parses params and converts them to object prepared for request
     * @param params the params to be parsed
     * @returns {{}} parsed
     */
    getQueryParams(params) {
        const queryParams = {
            dateFrom: null,
            dateTo: null,
            sortType: null,
            locale: null,
            entityId: null,
            entityIdMatchType: null,
            imageEntityId: null,
            imageEntityIdMatchType: null,
            nextToken: null,
            queryExecutionId: null,
            maxResults: null,
        };
        if (params) {
            if (params.startDate && params.endDate) {
                queryParams.dateFrom = this.convertDate(params.startDate);
                queryParams.dateTo = this.convertDate(params.endDate);
            }
            queryParams.sortType = params.sortType;
            queryParams.locale = params.locale;
            if (params.entityId) {
                queryParams.entityId = params.entityId;
                queryParams.entityIdMatchType = params.entityIdMatchType;
            }
            if (params.imageEntityId) {
                queryParams.imageEntityId = params.imageEntityId;
                queryParams.imageEntityIdMatchType = params.imageEntityIdMatchType;
            }
            queryParams.nextToken = params.nextToken;
            queryParams.queryExecutionId = params.queryExecutionId;
            queryParams.maxResults = params.maxResults;
        }
        return queryParams;
    }

    /**
     * Format date correctly
     * @param date the date to be formatted
     * @returns {string} the formatted date in milliseconds as string
     */
    convertDate(date) {
        return new Date(date).getTime().toString();
    }

    /**
     * Function encoding query parameters and returning them
     * @param params the params to be encoded
     * @returns {string} string query
     */
    encodeQueryParams(params) {
        const paramsList = [];
        for (let param in params) {
            if (params[param]) {
                paramsList.push(encodeURIComponent(param) + '=' + encodeURIComponent(params[param]));
            }
        }
        return (paramsList.length > 0 ? '?' : '') + paramsList.join('&');
    }
}