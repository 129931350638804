import React from "react";
import {Select, TextFilter} from "@amzn/awsui-components-react";
import {getInitialOption} from "../../helpers/visualiserFunctions";

/**
 * Selector for locale for the Visualiser dashboard
 */
export default class VisualiserEntityIdFilter extends React.Component {

    /**
     * Constructor for VisualiserEntityIdFilter
     * @param props takes props onChangeOption that gets triggered whenever entityId changes
     */
    constructor(props) {
        super(props);
        this.options = [
            { label: "Exact match", value: "EXACT" },
            { label: "Contains", value: "CONTAINS" },
            { label: "Starts with", value: "STARTS_WITH" }
        ];
        this.state = {
            selectedOption: getInitialOption(props, this.options)
        };
    }

    /**
     * Callback function for changing entityId for filters
     * @param text the text representing entityId
     */
    onChange(text) {
        this.setState({ text: text });
        this.props.onChangeEntityId(text);
    }

    /**
     * Callback function for changing match type for entity
     * @param selectedOption the type of match type selected
     */
    onChangeMatchType(selectedOption) {
        this.setState({ selectedOption: selectedOption });
        this.props.onChangeOption(selectedOption.value);
    }

    render() {
        return (
            <div className="entityFilterWrapper">
                <TextFilter
                    filteringText={this.props.text}
                    filteringPlaceholder={this.props.placeholder}
                    onChange={({ detail }) => this.onChange(detail.filteringText)}
                    className="entityTextFilter"
                />
                <Select
                    selectedOption={this.state.selectedOption}
                    onChange={({ detail }) => this.onChangeMatchType(detail.selectedOption)}
                    options={this.options}
                    selectedAriaLabel="Selected"
                    placeholder={"Select match type"}
                    className="entitySelect"
                />
            </div>
        );
    }
}