import Joi from "joi";

//Schema for getImages form.
const getImagesSchema = Joi.object({
  imageId: Joi.string().required(),
  maxHeight: Joi.number().integer().min(0).allow(''),
  maxWidth: Joi.number().integer().min(0).allow(''),
  minHeight: Joi.number().integer().min(0).allow(''),
  minWidth: Joi.number().integer().min(0).allow(''),
  aspectRatio: Joi.number().min(0).allow('')
});

//Schema for discoverImages
export const discoverImagesSchema = Joi.object({
  //objects are optional by default.
  entities: Joi.array().items(
    Joi.object({
      id: Joi.string().required(),
      namespace: Joi.string().required().valid('WIKIDATA_ID', 'IMDB_ID','EVI_TKID','GRAPHIQ_ID','WIKIPEDIA_URL','GRAPHIQ_ALDE_ID', 'UAC'),
      importance_weight: Joi.number(),
    })
  ).required(),
  preferred_dimensions: Joi.object({
    max_height: Joi.number().integer().min(0),
    max_width: Joi.number().integer().min(0),
    min_height: Joi.number().integer().min(0),
    min_width: Joi.number().integer().min(0),
    aspect_ratio: Joi.number().min(0)
  }),
  ranker: Joi.string().valid('AESTHETIC', 'RELEVANCE', 'ASPECT_RATIO'),
  pagination_request: Joi.object({
    from: Joi.number().integer().min(0), 
    size: Joi.number().integer().min(1)
  }),
  request_metadata: Joi.object({
    locale: Joi.string(),
    device_type_id: Joi.string(),
    marketplace_id: Joi.string(),
    country_of_residence: Joi.string()
  }),
  tags: Joi.array().items(Joi.string())
})

//Schema for discoverImages v2
export const discoverImagesV2Schema = Joi.object({
  //objects are optional by default.
  query_text: Joi.string(),
  entities: Joi.array().items(
    Joi.object({
      id: Joi.string().required(),
      namespace: Joi.string().required().valid('WIKIDATA_ID', 'IMDB_ID','EVI_TKID','GRAPHIQ_ID','WIKIPEDIA_URL','GRAPHIQ_ALDE_ID', 'UAC'),
      is_optional: Joi.boolean(),
    })
  ),
  image_url: Joi.string(),
  preferred_dimensions: Joi.object({
    max_height: Joi.number().integer().min(0),
    max_width: Joi.number().integer().min(0),
    min_height: Joi.number().integer().min(0),
    min_width: Joi.number().integer().min(0),
    aspect_ratio: Joi.number().min(0)
  }),
  response_dimensions: Joi.object({
    size: Joi.number().integer().min(1)
  }),
  rankers: Joi.array().items(
    Joi.string().valid('AESTHETIC', 'RELEVANCE', 'ASPECT_RATIO', 'RECENCY')
  ),
  ranker_parameters: Joi.object({
    recency_weight: Joi.number().min(0).max(1)
  }),
  tag_filters: Joi.array().items(
    Joi.object({
      tag: Joi.string().required().valid('LOGO_SPORTS', 'FLAG_ICONS', 'HEADSHOT_SPORTS', 'ALBUM_ARTWORK', 'EDITORIAL'),
      filter_type: Joi.string().valid('REQUIRE', 'EXCLUDE')
    })
  ),
  date_range_filter: Joi.object({
    min_date: Joi.string(),
    max_date: Joi.string()
  }),
  request_metadata: Joi.object({
    locale: Joi.string(),
    device_type_id: Joi.string(),
    marketplace_id: Joi.string(),
    country_of_residence: Joi.string(),
    customer_id: Joi.string(),
    utterance_id: Joi.string(),
    profile: Joi.string(),
    is_child_directed: Joi.boolean()
  }),
  custom_config: Joi.object({
    query_endpoint: Joi.string(),
    model_stack_key: Joi.string(),
    embedding_search_domain_endpoint: Joi.string(),
    reranker_endpoint_name: Joi.string(),
    reranker_threshold: Joi.number().min(0).max(1),
    ltr_endpoint: Joi.string(),
    num_images_to_ltr: Joi.number().integer().min(0),
    web_images_embedding_search_domain_endpoint: Joi.string(),
    enable_ranker: Joi.boolean(),
    content_source_map: Joi.object({
      CE: Joi.boolean(),
      NRT: Joi.boolean(),
      WIR: Joi.boolean(),
    })
  }),
  query_metadata: Joi.object({
    grounding_query: Joi.string().required(),
    answer: Joi.string().required(),
    documents: Joi.array().items(
      Joi.object({
        type: Joi.string().required().valid('CITATIONS', 'GROUNDING', 'WIR'),
        url: Joi.string().required(),
        source: Joi.string().required().valid('WEB', 'GRAPHIQ', 'ALEXA_ANSWERS'),
    }))
  })
})

//Schema for discoverEditorialImages
export const discoverEditorialImagesSchema = Joi.object({
  //objects are optional by default.
  entities: Joi.array().items(
    Joi.object({
      id: Joi.string().required(),
      namespace: Joi.string().required().valid('WIKIDATA_ID', 'IMDB_ID','EVI_TKID','GRAPHIQ_ID','WIKIPEDIA_URL','GRAPHIQ_ALDE_ID', 'UAC'),
      importance_weight: Joi.number(),
    })
  ).required(),
  preferred_dimensions: Joi.object({
    max_height: Joi.number().integer().min(0),
    max_width: Joi.number().integer().min(0),
    min_height: Joi.number().integer().min(0),
    min_width: Joi.number().integer().min(0),
    aspect_ratio: Joi.number().min(0)
  }),
  rankers: Joi.array().items(Joi.string()),
  pagination_request: Joi.object({
    from: Joi.number().integer().min(0), 
    size: Joi.number().integer().min(1)
  }),
  request_metadata: Joi.object({
    locale: Joi.string(),
    device_type_id: Joi.string(),
    marketplace_id: Joi.string(),
    country_of_residence: Joi.string()
  })
})

export const discoverImagesWithUACSchema = Joi.object({
  entityCriteria: Joi.array().items(
    Joi.object({
      entity: Joi.object({
        //have @id in quotes because can't have that as a key object.
        "@id": Joi.string().required()
      }).required()
    })
  ).required().min(1),
  preferences: Joi.object({
    dimensions: Joi.object({
      maxHeight: Joi.number().integer().min(0),
      maxWidth: Joi.number().integer().min(0),
      minHeight: Joi.number().integer().min(0),
      minWidth: Joi.number().integer().min(0),
      aspectRatio: Joi.number().min(0)
    })
  }),
  paginationContext: Joi.object({
    maxResults: Joi.number().integer().min(1),
    nextToken: Joi.string()
  })
});

export function isGetImagesInputValid(input) {
  const { error } = getImagesSchema.validate(input);
  return error;
}

/**
 * Parses body in json, validates against schema, 
 * and if there was an error returns the error message. 
 * Otherwise returns undefined.
 */
export function isRequestBodyValid(schema, requestBody) {
  try {
    //parse text into JSON Object -> could throw exception if text not a valid json
    let requestObject = JSON.parse(requestBody);
    //validate against schema:
    const { error } = schema.validate(requestObject);
    if(error !== undefined) {
      return error.message; 
    }
    return undefined;
  } catch (exception) {
    //when string wasn't a valid json.
    return exception.name + ": " + exception.message;
  }
}

/**
 * Validate whether selected date range is valid
 * @param dateRange the date range to be validated
 * @return {{valid: boolean, errorMessage: string}|{valid: boolean}} object denoting whether the date range is valid
 */
export function validateDateRange(dateRange) {
  if (dateRange.type === "absolute") {
    return validateAbsoluteRange(dateRange);
  }
  return validateRelativeRange(dateRange);
}

/**
 * Validate dateRange for absolute type
 * @param dateRange the date range to be validated
 * @return {{valid: boolean, errorMessage: string}|{valid: boolean}} object denoting whether the date range is valid
 */
function validateAbsoluteRange(dateRange) {
  const maxDays = 31;
  if (!dateRange.startDate || !dateRange.endDate) {
    return {
      valid: false,
      errorMessage: "The selected date range is incomplete. Select a start and end date for the date range."
    };
  }
  if (dateDiffInDays(dateRange.startDate, dateRange.endDate) > maxDays) {
    return {
      valid: false,
      errorMessage: "The selected date range is too large. Maximum range is 1 month."
    };
  }
  return { valid: true };
}

/**
 * Validate dateRange for relative type
 * @param dateRange the date range to be validated
 * @return {{valid: boolean, errorMessage: string}|{valid: boolean}} object denoting whether the date range is valid
 */
function validateRelativeRange(dateRange) {
  const maxMonths = 1;
  const maxWeeks = 4;
  const maxDays = 31;
  const maxHours = 744;
  const maxMinutes = 44640;
  const maxSeconds = 2678400;
  if (isNaN(dateRange.amount)) {
    return {
      valid: false,
      errorMessage: "The selected date range is incomplete. Specify a duration for the date range."
    };
  }
  if (("second" === dateRange.unit && dateRange.amount > maxSeconds) ||
      ("minute" === dateRange.unit && dateRange.amount > maxMinutes) ||
      ("hour" === dateRange.unit && dateRange.amount > maxHours) ||
      ("day" === dateRange.unit && dateRange.amount > maxDays) ||
      ("week" === dateRange.unit && dateRange.amount > maxWeeks) ||
      ("month" === dateRange.unit && dateRange.amount > maxMonths) ||
      "year" === dateRange.unit) {
    return {
      valid: false,
      errorMessage: "The selected date range is too large. Maximum range is 1 month."
    };
  }
  return { valid: true };
}

/**
 * Get difference between 2 dates in days
 * @param startDate the start date
 * @param endDate the end date
 * @return {number} the days in between the 2 dates
 */
function dateDiffInDays(startDate, endDate) {
  const millisInDay = 864e5;
  const diff = Math.abs(new Date(endDate) - new Date(startDate));
  return Math.ceil(diff / millisInDay);
}