/**
 * Get date value based on selected relative range
 * @param value the value selected
 * @param date the date to be altered by the relative range
 */
import {validateDateRange} from "../validator/InputValidator";
import {calculateRelativeTimeValue} from "../components/filters/VisualiserDateRangePicker";

/**
 * Get pre-filled filters based on query params received
 * @param search the query params received
 */
export function getFiltersFromQueryParams(search) {
    const params = new URLSearchParams(search);
    const date = new Date();
    const fallbackEndDate = date.toISOString();
    const fallbackStartDate = calculateRelativeTimeValue({"amount": 1, "unit": "hour"}, date).toISOString();
    const isValidDateRange = validateDateRange({
        type: 'absolute',
        startDate: params.get('startDate'),
        endDate: params.get('endDate')
    }).valid;
    return {
        startDate: isValidDateRange ? params.get('startDate') : fallbackStartDate,
        endDate: isValidDateRange ? params.get('endDate') : fallbackEndDate,
        locale: params.get('locale'),
        sortType: params.get('sortType'),
        entityId: params.get('entityId'),
        entityIdMatchType: params.get('entityIdMatchType'),
        imageEntityId: params.get('imageEntityId'),
        imageEntityIdMatchType: params.get('imageEntityIdMatchType')
    };
}

/**
 * Parse filters object and remove null fields
 * @param filters the filters to be parsed
 */
export function parseFiltersToParams(filters) {
    const params = {...filters};
    for (const key of Object.keys(params)) {
        if (!params[key]) {
            delete params[key];
        }
    }
    if ('entityIdMatchType' in params && !('entityId' in params)) {
        delete params.entityIdMatchType;
    }
    if ('imageEntityIdMatchType' in params && !('imageEntityId' in params)) {
        delete params.imageEntityIdMatchType;
    }
    return params;
}

/**
 * Get initial option based on passed initial value
 */
export function getInitialOption(props, options, alwaysChangeOption = true) {
    if (!props.initialOption) {
        return options[0];
    }
    const index = options.findIndex(option => option.value === props.initialOption);
    const selectedOption = options[index === -1 ? 0 : index];
    // we want to allow people specify any locale in the URL (and try to search for it)
    if (alwaysChangeOption || index !== -1) {
        props.onChangeOption(selectedOption.value);
    }
    return selectedOption;
}