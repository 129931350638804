// Example request body for Discover Images taken from: 
// https://code.amazon.com/packages/AlexaMultimediaDiscoveryService/blobs/mainline/--/data/v1/discover/images/request-with-single-entity.json
export const exampleDiscoverImagesRequestBody = JSON.stringify(
    {
      "entities": [
        {
          "id": "nm2225369",
          "namespace": "IMDB_ID",
          "importance_weight": 1
        }
      ],
      "preferred_dimensions": {
        "min_height": 0,
        "max_height": 960,
        "min_width": 0,
        "max_width": 1280,
        "aspect_ratio": 0
      },
      "ranker": "ASPECT_RATIO",
      "pagination_request": {
        "from": 0,
        "size": 10
      },
      "request_metadata": {
        "locale": "string",
        "device_type_id": "string",
        "marketplace_id": "string"
      }
    }, null, 2);
    