import React from "react";
import { Form, FormField, Button, Textarea } from "@amzn/awsui-components-react/polaris";
import Result from "../model/Result";
import { isRequestBodyValid, discoverEditorialImagesSchema } from "../validator/InputValidator";
import { exampleDiscoverImagesEditorialRequestBody } from "../helpers/exampleDiscoverEditorialImagesRequestBody";

export default class DiscoverEditorialImages extends React.Component {

  state = {
    requestBody: exampleDiscoverImagesEditorialRequestBody,
    result: null,
    formValidationError: "",
    responseTitle: "DiscoverEditorialImages Response",
    isFormSubmitted: false
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({requestBody: event.detail.value})
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.resetState();

    //request body validation
    const formValidationErrorMessage = isRequestBodyValid(discoverEditorialImagesSchema, this.state.requestBody);
    if (formValidationErrorMessage !== undefined) {
      //error while validating schema.
      this.setState({formValidationError: formValidationErrorMessage});
      return;
    }

    //show loading table
    this.setState({
      isFormSubmitted: true,
      result: {"images": []}
    })

    await this.discoverEditorialImagesFromApi();
  }

  //call DiscoverEditorialImages API
  discoverEditorialImagesFromApi = async () => {
    const response = await this.props.tracerServiceClient.discoverEditorialImages(this.state.requestBody);
    this.setState({ result: response });
  }

  //handler for resetForm button - resets request body to example request.
  resetForm = () => {
    this.setState({ requestBody: exampleDiscoverImagesEditorialRequestBody });
  }

  resetState = () => {
    this.setState({
      formValidationError: "",
      isFormSubmitted: false,
      result: null
    })
  }

  render() {

    return (
      <div className='DiscoverEditorialImages'>
      <div style={{ marginLeft: '5%', marginRight: '5%'}}>
        <Form header="Call to AMD DiscoverEditorialImages API"
          errorText={this.state.formValidationError}
          actions={
            <div>
              <br />
              <Button formAction="none" onClick={this.resetForm} variant="primary"> Reset Form </Button>
              &nbsp;&nbsp;&nbsp;
              <Button formAction="submit" onClick={this.handleSubmit} variant="primary"> Call AMD </Button>
            </div>
          }
        >
          <FormField label={<span>Request Body <b style={{color:'red'}}>*</b></span>}>
            <Textarea type="text" value={this.state.requestBody} onChange={this.handleChange} rows={15}/>
          </FormField>
        </Form>
      </div>

      {this.state.isFormSubmitted === true && this.state.result !== null
      ? <Result data={this.state.result} title={this.state.responseTitle} /> 
      : <div/>}
      
    </div>
    );       
  }
}
