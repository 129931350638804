// Example request body for Discover Editorial Images  
export const exampleDiscoverImagesEditorialRequestBody = JSON.stringify(
    {
      "entities": [
        {
          "id": "nm2225369",
          "namespace": "IMDB_ID",
          "importance_weight": 1
        }
      ],
      "preferred_dimensions": {
        "min_height": 0,
        "max_height": 960,
        "min_width": 0,
        "max_width": 1280,
        "aspect_ratio": 0
      },
      "rankers": [],
      "pagination_request": {
        "from": 0,
        "size": 10
      },
      "request_metadata": {
        "locale": "string",
        "device_type_id": "string",
        "marketplace_id": "string"
      }
    }, null, 2);
    