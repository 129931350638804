import React from "react";
import VisualiserDashboardItem from "../components/VisualiserDashboardItem";
import VisualiserDashboardItemDetail from "../components/VisualiserDashboardItemDetail";

/**
 * Component representing Visualiser Dashboard showing grid of images
 */
export default class VisualiserDashboard extends React.Component {

    /**
     * Constructor for VisualiserDashboard
     * @param props takes no props
     */
    constructor(props) {
        super(props);
        this.state = {
            detailImage: null
        }
    }

    /**
     * Method that sorts which button/image was clicked.
     * @param event event handled
     * @param image image to be shown more info about
     */
    handleClick = (event, image) => {
        event.preventDefault();
        this.setState({detailImage: image});
    }

    render() {
        return (
            <div>
                <div id="visualiserGrid" className="visualiserGrid" style={{gridTemplateColumns: `repeat(${this.props.numberOfColumns}, minmax(0, 1fr))`}}>
                    {
                        this.props.results.map(item =>
                            <VisualiserDashboardItem
                                key={item.imageId}
                                item={item}
                                onImageClicked={(event) => this.handleClick(event, item)}
                                isSlideshow={this.props.isSlideshow}
                            />
                        )
                    }
                </div>
                {(this.state.detailImage !== null)
                    ?
                    <VisualiserDashboardItemDetail
                        detailImage={this.state.detailImage}
                        onDismiss={() => this.setState({ detailImage: null })}
                        stage={this.props.stage}
                    />
                    :
                    null
                }
            </div>
        );
    }
}