import React from "react";
import {Select} from "@amzn/awsui-components-react";
import {getInitialOption} from "../../helpers/visualiserFunctions";

/**
 * Selector for locale for the Visualiser dashboard
 */
export default class VisualiserLocaleSelect extends React.Component {

    /**
     * Constructor for VisualiserLocaleSelect
     * @param props takes props onChangeOption that gets triggered whenever locale changes
     */
    constructor(props) {
        super(props);
        this.options = [
            { label: "🌍 All locales", value: null },
            { label: "🇺🇸 en-US", value: "en-US" },
            { label: "🇬🇧 en-GB", value: "en-GB" },
            { label: "🇩🇪 de-DE", value: "de-DE" },
            { label: "🇨🇦 en-CA", value: "en-CA" },
            { label: "🇦🇺 en-AU", value: "en-AU" },
            { label: "🇫🇷 fr-FR", value: "fr-FR" },
            { label: "🇨🇦 fr-CA", value: "fr-CA" },
            { label: "🇮🇹 it-IT", value: "it-IT" },
            { label: "🇪🇸 es-ES", value: "es-ES" },
            { label: "🇺🇸 es-US", value: "es-US" },
            { label: "🇮🇳 en-IN", value: "en-IN" },
            { label: "🇯🇵 ja-JP", value: "ja-JP" },
            { label: "🇲🇽 es-MX", value: "es-MX" },
            { label: "🇮🇳 hi-IN", value: "hi-IN" },
            { label: "🇧🇷 pt-BR", value: "pt-BR" },
            { label: "🇸🇦 ar-SA", value: "ar-SA" }
        ];
        this.state = {
            selectedOption: getInitialOption(props, this.options, false)
        };
    }

    /**
     * Callback function for changing locale for filters
     * @param selectedOption the type of filter selected
     */
    onChange(selectedOption) {
        this.setState({ selectedOption: selectedOption });
        this.props.onChangeOption(selectedOption.value);
    }

    render() {
        return (
            <Select
                selectedOption={this.state.selectedOption}
                onChange={({ detail }) => this.onChange(detail.selectedOption)}
                options={this.options}
                selectedAriaLabel="Selected"
                placeholder={"Filter by locale"}
            />
        );
    }
}