import ApiCallerClient, {ApiMethod} from "./apiCallerClient";

/**
 * Class that handles API calls for creation of tiny URL from given URL
 */
export default class TinyUrlServiceClient {

    /**
     * Get Tiny URL API. Queries the tiny.amazon.com service to shorten URL.
     * @param url URL to be shortened
     * @returns {Promise<string|*|Response>} response with data or error
     */
    getTinyURL = async (url) => {
        let path = '/submit/url?name=' + encodeURIComponent(url) + '&comment=Visualizer';
        return await ApiCallerClient.callApi({
            "endpoint": {
                "hostName": 'https://tiny.amazon.com',
                "httpMethod": ApiMethod.GET,
                "path": path
            },
            "body": null
        }, {
            "Accept": "application/json"
        });
    }
}