import React from "react";
import {Badge, Cards, Modal, Popover} from "@amzn/awsui-components-react";
import {Button} from "@amzn/awsui-components-react/polaris";

const EMT_BETA_URL = 'https://evi-multimedia.integ.amazon.com/#/viewImages/';
const EMT_PROD_URL = 'https://evi-multimedia-tools.aka.amazon.com/#/viewImages/';

/**
 * Detail of an image showing more information about it
 */
export default class VisualiserDashboardItemDetail extends React.Component {

    /**
     * Get top image
     */
    getImageFromURL = () => {
        const url = this.props.detailImage["imageUrl"];
        return <img src={url} alt={url} className="detailImage"/>
    }

    /**
     * Display all image properties in a card.
     */
    createCardForImage = () => {
        const imageAttributes = this.props.detailImage;
        let cardDefinition = {
            sections: Object.keys(imageAttributes).map((key) => {
                return {
                    id: key,
                    header: this.renderAttributeName(key),
                    width: 50,
                    content: item => this.getCardSectionContent(item, key)
                }
            })
        };
        return <Cards cardDefinition={cardDefinition} items={[imageAttributes]}/>
    }

    /**
     * Get content of the section of a card.
     * @param item The item to be shown
     * @param key The key of the attribute to be shown in this section
     * @return {JSX.Element|*} Element to be shown, differs by specified key
     */
    getCardSectionContent(item, key) {
        if (key === "imageUrl") {
            return <a href={item[key]} target="_blank" rel="noopener noreferrer">Link to the Image</a>;
        } else if (Array.isArray(item[key])) {
            return this.getEntities(item, key);
        }
        return item[key];
    }

    /**
     * Format name of the attribute from camelCase to sentence.
     * @param key the key to be formatted
     * @return {string} the formatted string
     */
    formatAttributeName(key) {
        const result = key.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    }

    /**
     * Get title of element.
     * @param key the key to get the title for
     */
    renderAttributeName(key) {
        const title = this.formatAttributeName(key);
        const descriptions = { 'imageEntities': 'Entities linked with this image',
            'inputEntities': 'Entities this image was requested for',
            'expandedEntities': 'Expanded entities this image was requested for'
        };
        if (descriptions.hasOwnProperty(key)) {
            return <div>
                {title}
                <Popover
                    dismissAriaLabel="Close"
                    size="medium"
                    triggerType="custom"
                    content={descriptions[key]}
                >
                    <Button iconName="status-info" variant="icon"/>
                </Popover>
            </div>;
        }
        return title;
    }

    /**
     * Get entities as badges
     * @param item the item to be shown
     * @param key the key for the attribute to be processed
     */
    getEntities(item, key) {
        const values = item[key];
        return <div key={item.imageId} className="entitiesWrapper">
            {values.map(value =>
                <div key={value.id} className="entityWrapper">
                    <Badge color="grey">
                        <div>
                            <p className="entityTitle">{value.namespace}</p>
                            <p className="entityValue">{value.id}</p>
                        </div>
                    </Badge>
                </div>
            )}
        </div>;
    }

    render() {
        let url = (this.props.stage === "PROD" ? EMT_PROD_URL : EMT_BETA_URL) + this.props.detailImage.imageId;
        return (
            <Modal
                header={
                    <div>
                        Image Detail
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href={url} className="buttonLink" target="_blank" rel="noopener noreferrer">
                            <Button variant="primary">Open in EMT</Button>
                        </a>
                    </div>
                }
                visible={true}
                onDismiss={() => this.props.onDismiss()}
            >
                {this.getImageFromURL()}
                {this.createCardForImage()}
            </Modal>
        );
    }
}