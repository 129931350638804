import React from "react";
import DateRangePicker from "@amzn/awsui-components-react/polaris/date-range-picker";
import {validateDateRange} from "../../validator/InputValidator";

/**
 * Get date picker component used for filtering
 */
export default class VisualiserDateRangeFilter extends React.Component {

    /**
     * Constructor for VisualiserDateRangeFilter
     * @param props takes props onChangeDate, initialStartDate, initialEndDate
     */
    constructor(props) {
        super(props);
        this.state = {
            date: getInitialDateRange(props.initialStartDate, props.initialEndDate)
        };
    }

    /**
     * Callback function for changing date for filters
     * @param detail the date values to be updated
     */
    onChange(detail) {
        if (detail.value && detail.value.type === 'relative') {
            const date = new Date();
            detail.value.endDate = date.toISOString();
            detail.value.startDate = calculateRelativeTimeValue(detail.value, date).toISOString();
        }
        this.setState({date: detail.value});
        let startDate = null;
        let endDate = null;
        if (detail.value && detail.value.startDate && detail.value.endDate) {
            startDate = detail.value.startDate;
            endDate = detail.value.endDate;
        } else {
            // clear means setting 1 hour (default)
            this.onChange({
                value: getDefaultDateRange()
            });
            return;
        }
        this.props.onChangeDate(startDate, endDate);
    }

    /**
     * Get relative options for the DateRangePicker
     */
    getRelativeOptions() {
        return [
            {
                key: "previous-1-hour",
                amount: 1,
                unit: "hour",
                type: "relative"
            },
            {
                key: "previous-6-hours",
                amount: 6,
                unit: "hour",
                type: "relative"
            },
            {
                key: "previous-1-day",
                amount: 1,
                unit: "day",
                type: "relative"
            }
        ];
    }

    /**
     * Get strings for the DateRangePicker components
     */
    getI18Strings() {
        return {
            customRelativeRangeDurationLabel: "Duration",
            customRelativeRangeDurationPlaceholder: "Enter duration",
            customRelativeRangeOptionLabel: "Custom range",
            customRelativeRangeOptionDescription: "Set a custom range in the past",
            customRelativeRangeUnitLabel: "Unit of time",
            formatRelativeRange: value => {
                const time = 1 === value.amount ? value.unit : value.unit + "s";
                return `Last ${value.amount} ${time}`;
            },
            formatUnit: (unit, value) => (1 === value ? unit : unit + "s"),
            relativeModeTitle: "Relative range",
            absoluteModeTitle: "Absolute range",
            relativeRangeSelectionHeading: "Choose a range",
            startDateLabel: "Start date",
            endDateLabel: "End date",
            startTimeLabel: "Start time",
            endTimeLabel: "End time",
            clearButtonLabel: "Clear",
            cancelButtonLabel: "Cancel",
            applyButtonLabel: "Select"
        };
    }

    render() {
        return (
            <DateRangePicker
                onChange={(detail) => this.onChange(detail.detail)}
                value={this.state.date}
                relativeOptions={this.getRelativeOptions()}
                i18nStrings={this.getI18Strings()}
                placeholder="Filter by a date range"
                isValidRange={validateDateRange}
            />
        );
    }
}

/**
 * Calculate relative time from the selected one.
 * @param value the value selected
 * @param date current date to be modified
 */
export function calculateRelativeTimeValue(value, date) {
    switch (value.unit) {
        case 'second':
            date.setSeconds(date.getSeconds() - value.amount);
            break;
        case 'minute':
            date.setMinutes(date.getMinutes() - value.amount);
            break;
        case 'hour':
            date.setHours(date.getHours() - value.amount);
            break;
        case 'day':
            date.setDate(date.getDate() - value.amount);
            break;
        case 'week':
            date.setDate(date.getDate() - (value.amount * 7));
            break;
        case 'month':
            date.setMonth(date.getMonth() - value.amount);
            break;
        case 'year':
            date.setFullYear(date.getFullYear() - value.amount);
            break;
        default:
            break;
    }
    return date;
}

/**
 * Get default date range for creation and reset
 */
export function getDefaultDateRange() {
    return {
        key: "previous-1-hour",
        amount: 1,
        unit: "hour",
        type: "relative"
    };
}

/**
 * Get initial date range from props
 */
export function getInitialDateRange(startDate, endDate) {
    const isoStringCharCountMin = 24;
    const isoStringCharCountMax = 27;
    if (!startDate || !endDate || startDate.length < isoStringCharCountMin || startDate.length > isoStringCharCountMax ||
        endDate.length < isoStringCharCountMin || endDate.length > isoStringCharCountMax || !Date.parse(startDate) || !Date.parse(endDate)) {
        return getDefaultDateRange();
    }
    const diffInSeconds = Math.abs(Date.parse(startDate) - Date.parse(endDate)) / 1000;
    const hours = Math.floor(diffInSeconds / 3600) % 24;
    const diffInSecondsFromNow = Math.abs(new Date() - Date.parse(endDate)) / 1000;
    // if the default range is selected, set it as relative
    if (hours === 1 && diffInSecondsFromNow <= 1) {
        return getDefaultDateRange();
    }
    return {
        type: 'absolute',
        startDate: convertToDateWithTimezone(startDate),
        endDate: convertToDateWithTimezone(endDate)
    }
}

/**
 * The DateRangePicker only accepts date formatted with timezone, so this converts the casual ISO date to the one with timezone
 * @param date the date to be processed
 * @return {string} the date with timezone
 */
function convertToDateWithTimezone(date) {
    if (date.includes('+')) {
        return date;
    }
    return date.slice(0, -5) + "+00:00";
}