import {SegmentedControl} from "@amzn/awsui-components-react";
import React from "react";
import Slider, {createSliderWithTooltip} from "rc-slider";
import 'rc-slider/assets/index.css'
import CopyURLButton from "./CopyURLButton";

const SliderWithTooltip = createSliderWithTooltip(Slider);

/**
 * Bar on top of filters used for other non-filter configurations
 */
export default class VisualiserConfigBar extends React.Component {

    render() {
        return (
            <div className="visualiserDashboardFilters">
                <div className="configRow">
                    <div className="configLeft">
                        <div className="segmentedControlContainer">
                            <SegmentedControl
                                selectedId={this.props.selectedMode}
                                onChange={this.props.onChange}
                                options={[
                                    { text: "Dashboard", id: "dashboard" },
                                    { text: "Slideshow", id: "slideshow" }
                                ]}
                            />
                        </div>
                        <div className="sliderContainer">
                            <p className={"filterTitle"}>Number of columns</p>
                            <SliderWithTooltip
                                min={1}
                                max={10}
                                step={1}
                                onChange={this.props.onChangeColumns}
                                defaultValue={this.props.defaultNumberOfColumns}
                                tipFormatter={(value) => ` ${value} `}
                                trackStyle={{backgroundColor: '#EB7311'}}
                                handleStyle={{borderColor: '#EB7311', backgroundColor: '#EB7311'}}
                                railStyle={{backgroundColor: '#EAEDED'}}
                            />
                        </div>
                    </div>
                    <div className="configRight">
                        <CopyURLButton tinyUrlServiceClient={this.props.tinyUrlServiceClient}/>
                    </div>
                </div>
            </div>
        );
    }
}