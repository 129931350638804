import React from "react";
import {Button} from "@amzn/awsui-components-react";

/**
 * Component used as a redirect to correct environment for Visualiser
 */
export default class RedirectPage extends React.Component {

    render() {
        let redirect = 'us-east-1 region';
        if (this.props.stage === "GAMMA") {
            redirect = 'PROD environment';
        }
        const redirectUrl = 'https://prod.iad.amd-tracer.alexa.amazon.dev/';
        return <div className="centeredDiv">
            <p>{'Visualiser is only available in the ' + redirect + ', please redirect there.'}</p>
            <a href={redirectUrl} className="buttonLink">
                <Button variant="primary">
                    Redirect
                </Button>
            </a>
        </div>
    }
}